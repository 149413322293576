import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    
    window.animsInit = function() {
        const fadeIn = function() {
            gsap.utils.toArray(".a-fadeIn > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete complete",
                    },
                    opacity: 0, 
                    duration: 1,
                    scaleX: .9,
                    y: 50
                });
            });
        }
        
        const mockups = function() {
            gsap.to('.c-mockups li:nth-of-type(1), .c-mockups li:nth-of-type(5)', {
                scrollTrigger: { trigger: '.c-mockups', scrub: 2, }, y: 50
            });
            
            gsap.to('.c-mockups li:nth-of-type(2), .c-mockups li:nth-of-type(4)', {
                scrollTrigger: { trigger: '.c-mockups', scrub: 1, }, y: 50
            });
            
            gsap.to('.c-mockups li:nth-of-type(3)', {
                scrollTrigger: { trigger: '.c-mockups', scrub: 1, }, y: 50
            });
        }

        document.getElementsByClassName('c-mockups').length > 0 ? mockups() : false;
        document.getElementsByClassName('a-fadeIn').length > 0 ? fadeIn() : false;
    };
    
    

}, false)
