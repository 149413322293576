import validate from 'validate.js';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {

    const init = function(el, constraints) {

        const form = el;
        
        form.addEventListener("submit", function(ev) {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        const inputs = form.querySelectorAll("input, textarea, select");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", function(ev) {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
                console.clear();
                console.log(errors);
                showErrorsForInput(this, errors[this.name])
            });
        }

        function handleFormSubmit(form, input) {
            const errors = validate(form, constraints, { fullMessages: false });
            console.log(errors);
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            }
        };
        
        function showErrors(form, errors) {
            _.each(form.querySelectorAll("[required]"), function(input) {
                showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        function showErrorsForInput(input, errors) {
            const formGroup = closestParent(input.parentNode, "form-row");
            resetFormGroup(formGroup);
            if (errors) {
                formGroup.classList.add("has-error");                
                scroll({
                    top: el.parentNode.offsetTop,
                    behavior: "smooth"
                })
            } else {
                formGroup.classList.add("has-success");
            }
        };

        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };
        
        function resetFormGroup(formGroup) {
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
        }

        function startAjax() {
            form.querySelector('button').style.display = 'none';
            form.querySelector('.lds-ripple').style.display = 'inline-flex';
        }

        function finishAjax() {
            form.querySelector('button').style.display = 'inline-flex';
            form.querySelector('.lds-ripple').style.display = 'none';
        }

        const formToJSON = (elements) =>
          [].reduce.call(
            elements,
            (data, element) => {
                if (element.type === 'radio') {
                    if (element.checked) {
                        data[element.name] = element.value;
                    }
                } else {
                
                    if (element.name == 'phone') {
                        data[element.name] = element.value.replaceAll(" ", "");
                    } else {
                        data[element.name] = element.value;
                    }
                }
              return data;
            },
            {},
        );
          
        function submitForm() {
            let xmlHttp = new XMLHttpRequest(), 
                url = form.getAttribute('action');

            xmlHttp.onload = function() {
                console.log(xmlHttp.status);
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                    
                    //thanks.classList.add('is-active');
/*
                    setTimeout(function() {
                        thanks.classList.add('is-visible');
                        form.reset();
                    }, 10)
*/
                    
                    //console.log(xmlHttp.responseText);
                    //showMessage('Formularz wysłany - dziękujemy.');
                } else {
                    showMessage('Wystąpił błąd w wysyłaniu formularza.');
                }
               finishAjax();
            };
        
            console.clear();
            xmlHttp.open('post', url, true);
            xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xmlHttp.send(JSON.stringify(formToJSON(form.elements)));
            console.log(JSON.stringify(formToJSON(form.elements)));
        }

        function showMessage(message) {
            let container = form.querySelector('.js-messages--summary');
            container.style.display = 'block';
            container.innerHTML = message;
        }
        
        function showSuccess() {
            startAjax();
            submitForm();
        }
    };


    // Init
    
    const register = function() {
        const constraints = {   
            city: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },
                     
            email: {
                presence: { message: "Pole nie może być puste" },
                email: { message: 'Adres nieprawidłowy' }
            },

            firstname: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },

            lastname: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },
            
            number: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },
            
            postcode: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },
        }; 
        
        init(document.getElementById('register'), constraints);
    };
    
    document.getElementById('register') ? register() : false;

}, false);
