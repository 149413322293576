import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {
    
    window.runScroll = function(el) {
        scroll({
            top: document.querySelector(el).offsetTop,
            behavior: "smooth"
        })
    };

    const gtt = document.querySelectorAll("[data-target]");
    
    if (gtt.length > 0) {
        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', function(e) {
            	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;            
                runScroll(e.currentTarget.dataset.target);
            });
        }
    }

}, false);
